@import '../../../index.scss';

.hero-sec {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    .cont-txt {
        h3 {
            font-size: 16px;
        }
        h1 {
            color: $heading_cl;
            font-size: 70px;
            font-weight: bold;
        }

        h2 {
            color: $text_cl;
            font-size: 70px;
            font-weight: bold;
        }

        .entry-txt {
            font-size: 18px;
        }
    }

    @media ( max-width:992px) {
        min-height: 100vh;
        padding: 100px 0;
        .cont-txt {

            h1 {
                font-size: 30px;
            }
            h2 {
                font-size: 27px;
            }
        }
    }
}