// variables 

$dark_blue_cl:#0a192f;
$green_cl:#64ffda;
$text_cl:#8892b0;
$heading_cl:#ccd6f6;



html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family:"Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark_blue_cl;
  box-sizing: border-box;
  color: $text_cl;
  overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
        
    }

    &::-webkit-scrollbar-track { 
        background: $dark_blue_cl;
      }
      
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background:$text_cl;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background:$green_cl;
    }
}

img {
  max-width: 100%;
}

section {
  padding: 100px 0;
}



// general classes 

.green-txt {
  color: $green_cl;
}



.transparent-btn {
  color: $green_cl;
  background-color: #0000;
  border: 1px solid $green_cl;
  border-radius: 5px;
  padding: 1.25rem 1.75rem;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5;
  margin-top: 50px;
  &:hover {
    background-color: #64ffda1a;
  color: $green_cl;

  }
}