@import '../../index.scss';
.social-icons-parent {

    @media(max-width:992px){
        display: none;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 60px;


    a {
        color: $heading_cl;
        margin-bottom: 24px;
        transition: all 0.5s;
        display: flex;
        font-size: 20px;
        &:hover {
            transform: translateY(-5px);
            color: $green_cl;
        }
    }

    .line {
        width: 1px;
        height: 70px;
        background-color: $heading_cl;
    }
}


.mail-parent {
    @media(max-width:992px){
        display: none;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 60px;

    a {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        color: $heading_cl;
        margin-bottom: 24px;
        position: relative;
        letter-spacing: 2px;
        &:hover{
            transform: translateY(-5px);
            color: $green_cl;
            text-decoration: none;
        }
    }
    
    .line {
        width: 1px;
        height: 70px;
        background-color: $heading_cl;
    }
}