@import '../../../index.scss';
header {
    transform: translateY(0px);
    background-color: #0a192fd9;
    box-shadow: 0 10px 30px -10px #020c1bb3;
}
.header-container {
    height: 100px;
    background-color: transparent;
    display: flex;
    align-items: center;
    position: relative;
    .navbar {
        width: 100%;
        @media (min-width:993px) {
            padding: 0 45px;
        }
        @media(max-width:992px){
            position: static;
            padding-left: 0;

            div#basic-navbar-nav {
                position: absolute;
                background: $dark_blue_cl;
                width: 100%;
                left:0;
                bottom: -220px;
                z-index: 1000;
                padding: 24px;
            }
        }

        .navbar-toggler {
            span {
                background: url(../../../Assets/img/close.png);
                background-size: contain!important;
                background-repeat: no-repeat!important;
            }
            &.collapsed {
                span {
                    background: url(../../../Assets/img/menu.png);
                }
            }
        }
        .navbar-brand {
            transition: all 0.5s;
            &:hover {
                color: $heading_cl;
            }
        }
        a {
            color: $green_cl;
        }
        .navbar-nav {
            align-items: center;
            .nav-link {
                color: $heading_cl;
                margin-right: 16px;
                transition: all 0.5s;
                &:hover {
                    color: $green_cl;
                }
                .green-txt {
                    margin-right: 5px;
                }
            }
            .transparent-btn {
                margin-top: 0;
                padding: 10px;
            }
        }
    }

}