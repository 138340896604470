@import '../../../index.scss';
.other-projects-parent {
    .heading {
        text-align: center;
        h2 {
            color: $heading_cl;
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 16px;
        }

        a {
            color: $green_cl;
            margin-bottom: 32px;
            position: relative;
            display: inline-block;
            transition: all 0.5s;

            &::after {
                content: '';
                width: 0%;
                height: 1px;
                background-color: $green_cl;
                position: absolute;
                bottom: 0px;
                left: 0;
                transition: all 0.5s;
            }

            &:hover {
                text-decoration: none;
                &::after {
                    width: 100%;
                }
            }
        }
    }

    .project-parent {
        background-color:#112240;
        padding: 32px;
        border-radius: 8px;
        margin-bottom: 30px;
        box-shadow: 0 20px 30px -15px #020c1bb3;
        transition: all 0.5s;
        
        .project-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;
            .folder-icon {
                color: $green_cl;
                font-size: 35px;
            }
            .project-link {
                color: $text_cl;
            }
        }

        .project-content {
            .project-name {
                font-size: 20px;
                font-weight: bold;
                transition: all 0.5s;
                margin-bottom: 12px;
                a {
                    transition: all 0.5s;                    
                    color: $heading_cl;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .project-description {
                font-size: 14px;
                color: #a8b2d1;
            }
            .project-technologies {
                font-size: 13px;
                color: #a8b2d1;
                span:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }



        &:hover {
            transform: translateY(-7px);
            .project-name {
                a {
                    color: $green_cl;
                }
            }
        }
    }
}