@import '../../../index.scss';

.projects-sec {
    .sec-heading {
        display: flex;
        align-items: center;
        .green-txt {
            font-size: 25px;
        }
        font-size: 32px;
        color: $heading_cl;
        font-weight: 600;
        margin: 10px 0px 40px;

        .line {
            width:auto;
            min-width: 300px;
            height: 1px;
            background-color: $text_cl;
            margin-left: 24px;
            transition: all 0.5s;
        }

        &:hover {
            .line {
                background-color: $green_cl;
            }
        }

        @media (max-width:992px) {
            h2{
                font-size: 20px;
                font-weight: bold;
            }
            .line {
                min-width: 100px;
            }
            .green-txt {
                font-size: 18px;
            }
        }
    }

    .projects-parent {
        .cont {
            position: relative;
            
            @media(min-width:993px) {
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(12, 1fr);
                -webkit-box-align: center;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 100px;
                }
                &:nth-of-type(2n+1) {
                    .cont-txt {
                        grid-column: 7 / -1;
                        text-align: right;
                        .links-parent {
                            justify-content: flex-end;
                        }
                    }
                    .cont-img {
                        grid-column: 1 / 8;
                    }
                }
                .cont-txt {
                    position: relative;
                    grid-area: 1 / 1 / -1 / 7;
                    z-index: 10;
                }
                .cont-img {
                    grid-area: 1 / 6 / -1 / -1;
                    position: relative;
                    z-index: 1;
                }
            }

            

            .cont-txt {
                position: relative;
                
                z-index: 10;

                .project-name {
                    margin-bottom: 16px;
                    a{
                        color: $heading_cl;
                        transition: all 0.5s;
                        &:hover {
                            text-decoration: none;
                            color: $green_cl;
                        }
                    }
                }

                .description {
                    box-shadow: 0 10px 30px -15px #020c1bb3;
                    position: relative;
                    z-index: 2;
                    padding: 25px;
                    border-radius: 8px;
                    background-color: #112240;
                    color: #a8b2d1;
                    margin-bottom: 16px;
                }

                .tools {
                    margin-bottom: 16px;
                    span {
                        margin-left: 8px;
                    }
                }
                .links-parent {
                    display: flex;
                    align-items: center;
                    
                    a {
                        color: $heading_cl;
                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }
            }

            .cont-img {
                box-shadow: 0 10px 30px -15px #020c1bb3;
                transition: all 0.5s;
                
                .img-parent{
                    border-radius: 4px;
                    position: relative;
                    transition: all 1s;
                    img {
                        max-width: 100%;
                        z-index: 1;
                        border-radius: 8px;
                        position:relative;
    
                    }
                    
                    &::after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: #64ffda87;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        border-radius: 8px;
                        transition: all 0.5s;
                        filter: brightness(50%);
                    }
                    &:hover {
                        
                        &::after {
                            opacity: 0;
                        }
                    }
                }
            }

            @media(max-width:992px) {
                margin-bottom: 32px;
                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: $dark_blue_cl;
                    position: absolute;
                    top: 0%;
                    left: 0;
                    z-index: 9;
                    opacity: 0.8;
                }
                .cont-img {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    .img-parent {
                        height: 100%;
                        img {
                            border-radius: 0;
                            position: relative;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .cont-txt {
                    padding: 16px;
                    overflow: hidden;
                    .description {
                        padding: 16px 0;
                        background: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}