@import '../../../index.scss';


footer {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}


.contact-sec {
    text-align: center;
    width: 100%;

    .cont {
        .heading {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 16px;
            span {
                margin-right: 8px;
            }
            p {
                margin: 0;
                width: auto;
            }
        }

        h2 {
            color: $heading_cl;
            font-size: 50px;
            font-weight: bold;
        }

        p { 
            width: 70%;
            margin: 0 auto;
            margin-bottom: 32px;
        }

        .transparent-btn {
            margin-bottom: 16px;
            display: inline-block;
        }
    }
}

.footer-social-icons-parent {
    display: none;
    @media(max-width:992px) {
        display: flex;
    }
    justify-content: center;
    align-items: center;
    a {
        color: $heading_cl;
        margin: 24px 8px 0;
        transition: all 0.5s;
        display: flex;
        font-size: 20px;
        &:hover {
            transform: translateY(-5px);
            color: $green_cl;
        }
    }
}

.copy-rights {
    position: absolute;
    bottom: 0;
    padding:15px 0; 
}